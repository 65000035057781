// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-oferta-tsx": () => import("./../../../src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-templates-blog-blog-template-tsx": () => import("./../../../src/templates/blog/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-blog-template-tsx" */),
  "component---src-templates-category-category-template-tsx": () => import("./../../../src/templates/category/category-template.tsx" /* webpackChunkName: "component---src-templates-category-category-template-tsx" */),
  "component---src-templates-portfolio-portfolio-template-tsx": () => import("./../../../src/templates/portfolio/portfolio-template.tsx" /* webpackChunkName: "component---src-templates-portfolio-portfolio-template-tsx" */),
  "component---src-templates-product-product-template-tsx": () => import("./../../../src/templates/product/product-template.tsx" /* webpackChunkName: "component---src-templates-product-product-template-tsx" */)
}

